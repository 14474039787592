<script setup lang="ts">
import type {ComponentPublicInstance} from "@vue/runtime-core";
import type {ElementorElement} from "~/integration/wordpress/types";
import type {PropType} from "vue";

const props = defineProps({
  element: {
    type: Object as PropType<ElementorElement>,
    default: {},
    required: true
  },
  index: Number
})

let claseses = [`elementor-element-${props.element?.id}`];

let attrs = {} as {[key: string]: string};

switch (props.element?.elType) {
  case 'container': {
    claseses.push('e-con');
    if(props.element?.settings?.container_type === 'grid') {
      claseses.push('e-grid');
    }else {
      claseses.push('e-flex');
    }

    if (props.index === 0) {
      claseses.push('e-parent')
    } else {
      claseses.push('e-child')
    }
    if(props.element?.settings?.content_width) {
      claseses.push(`e-con-${props.element.settings.content_width}`);
    }else {
      claseses.push(`e-con-boxed`);
    }

    break
  }
  case 'widget': {
    claseses.push('elementor-widget');
    if(props.element?.widgetType) {
      attrs['data-widget_type'] = props.element.widgetType;
      claseses.push(`elementor-widget-${props.element.widgetType}`);
    }
    break
  }
}

if(props?.element?.settings?.button_type) {
  claseses.push(`elementor-button-${props.element.settings.button_type}`);
}

if(props?.element?.settings?.align) {
  claseses.push(`elementor-align-${props.element.settings.align}`);
}

const components = {
  'container': defineAsyncComponent(() => import('./Elements/Container.vue')),
  'widget': defineAsyncComponent(() => import('./Elements/Widget.vue'))
} as {[key: string]: ComponentPublicInstance | unknown};
</script>

<template>
  <div
      class="elementor-element"
      :data-element_type="element.elType"
      :class="claseses"
      :data-id="element.id"
      v-bind="attrs"
      :key="`element-${element.id}-${element.elType}`"
  >
    <component v-if="components[element.elType]" :is="components[element.elType]" :element="element"/>
  </div>
</template>